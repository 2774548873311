import React from 'react';
import "../dashboard/Dashboard.css"; // Make sure the path is correct

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer bg-white" id="dashFooter" style={{ background: 'white' }}>
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
              Copyright ©{' '}
              <a
                href="https://ludokheloyaar.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ludokheloyaar.com
              </a>{' '}
              2024
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
