import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "welcome": "Welcome, {{user}} to React",
        "totalAmount": "Total Withdraw Amount Today: ₹{{amount}}"
      }
    },
    hi: {
      translation: {
        "welcome": "{{user}}, रिएक्ट में आपका स्वागत है",
        "totalAmount": "आज की कुल निकासी राशि: ₹{{amount}}"
      }
    }
  },
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React already protects from XSS
  }
});

export default i18n;
