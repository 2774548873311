import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import "datatables.net";

const SucWithdraw = () => {
  const [user, setUser] = useState();
  const [totalAmount, setTotalAmount] = useState(0); // State for total amount

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
   const [loading, setLoading] = useState(true);


  // Use for pagination
  let [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days"),
  ]);

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handlePageClick = async (data) => {
    setPageNumber(data.selected + 1);
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(
        `${baseUrl}txn/withdrawalreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.datefind);
        setNumberOfPages(res.data.totalPages);
        calculateTotalAmount(res.data.datefind); // Calculate total amount here
      });
  };

  const calculateTotalAmount = (data) => {
    const today = new Date();
    const total = data.reduce((acc, row) => {
      const withdrawalDate = new Date(row.createdAt); // Assuming 'createdAt' is the withdrawal date
      // Check if the withdrawal is from today
      if (
        withdrawalDate.getFullYear() === today.getFullYear() &&
        withdrawalDate.getMonth() === today.getMonth() &&
        withdrawalDate.getDate() === today.getDate()
      ) {
        return acc + (row.amount || 0);
      }
      return acc;
    }, 0);
    setTotalAmount(total);
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, values]);

  if (user === undefined) {
    return null;
  }

  const createDownloadData = () => {
    handleExport();
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Withdrawal Amount",
        E: "Status",
        F: "Upi Id",
        G: "Action by",
      },
    ];

    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.User_id ? row.User_id.Name : "",
        C: row.User_id ? row.User_id.Phone : "",
        D: row.amount ? row.amount : "",
        E: row.status ? row.status : "",
        F: row.User_id ? row.User_id.upi_id : "N/A",
        G: row.action_by ? row.action_by.Name : "N/A",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "WithdrawalReport.xlsx");
  };

  const generateInvoice = (data) => {
    const doc = new jsPDF();

    // Apply color
    doc.setTextColor(0, 102, 204);
    doc.setFontSize(16);
    doc.text("Invoice", 20, 20);

    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text("KANIKA PLAYHUB PRIVATE LIMITED", 20, 30);
    doc.text("111 BLOCK D1 GALI NO 3, BADARPUR SOUTH,", 20, 40);
    doc.text("New Delhi, South Delhi - 110044, Delhi", 20, 50);
    doc.text("GST No: 08AAKCK6244M1ZZ", 20, 60);
    doc.text("Website: https://kanikaplayhub.com", 20, 70);
    doc.text(`Invoice Number: ${data._id}`, 20, 80);
    doc.text(`Invoice Date: ${dateFormat(data.createdAt).split(",")[0]}`, 20, 90);

    // Customer details
    doc.text(`Bill To:`, 20, 110);
    doc.text(`${data.User_id ? data.User_id.Name : ""}`, 20, 120);
    doc.text(`${data.User_id ? data.User_id.Phone : ""}`, 20, 130);
    doc.text(`${data.User_id ? data.User_id.email : ""}`, 20, 140);

    // Withdrawal details table
    doc.autoTable({
      startY: 150,
      head: [["Withdrawal ID", "User Name", "Phone", "Amount", "Status", "UPI ID", "Action By"]],
      body: [
        [
          data._id,
          data.User_id ? data.User_id.Name : "N/A",
          data.User_id ? data.User_id.Phone : "N/A",
          data.amount ? data.amount : "N/A",
          data.status ? data.status : "N/A",
          data.User_id ? data.User_id.upi_id : "N/A",
          data.action_by ? data.action_by.Name : "N/A",
        ],
      ],
    });

    // Add footer with total amount
    doc.text(`Total Amount: ₹${data.amount}`, 20, doc.autoTable.previous.finalY + 10);

    // Save the PDF
    doc.save(`Invoice_${data._id}.pdf`);
  };

  // Filter by admin/agent name
  const handleFilterByAdmin = (adminName) => {
    const filteredUserData = user.filter(
      (record) => record.action_by && record.action_by.Name === adminName
    );
    setUser(filteredUserData);
    calculateTotalAmount(filteredUserData); // Calculate total amount for filtered data
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Withdrawal Reports</h4>
              <h5>Total Withdraw Amount Today: ₹{totalAmount}</h5> {/* Display total amount */}
              <Button onClick={() => createDownloadData()} className="btn btn-primary">
                Export Data
              </Button>
              <DatePicker value={values} onChange={setValues} range />
              <select
                className="form-control col-sm-1 m-1 bg-dark text-light"
                id="page-limit"
                onChange={setpageLimit}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
              {/* Buttons for filtering by admin/agent */}
              <div className="admin-buttons">
                <Button onClick={() => handleFilterByAdmin("shailendra")} className="btn btn-outline-secondary m-1">
                  shailendra
                </Button>
                <Button onClick={() => handleFilterByAdmin("v k meena")} className="btn btn-outline-secondary m-1">
                  v k meena
                </Button>
                <Button onClick={() => handleFilterByAdmin("Tr")} className="btn btn-outline-secondary m-1">
                  Tr
                </Button>
                <Button onClick={() => handleFilterByAdmin("Ajeet")} className="btn btn-outline-secondary m-1">
                  Ajeet
                </Button>
              </div>
              <Table striped bordered hover responsive className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>User Name</th>
                    <th>Phone Number</th>
                    <th>Withdrawal Amount</th>
                    <th>Status</th>
                    <th>UPI ID</th>
                    <th>Action by</th>
                    <th>Action</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {user.map((row) => (
                    <tr key={row._id}>
                      <td>{row._id}</td>
                      <td>{row.User_id ? row.User_id.Name : ""}</td>
                      <td>{row.User_id ? row.User_id.Phone : ""}</td>
                      <td>{row.amount}</td>
                      <td>{row.status}</td>
                      <td>{row.User_id ? row.User_id.upi_id : "N/A"}</td>
                      <td>{row.action_by ? row.action_by.Name : "N/A"}</td>
                      <td>
                        <Button onClick={() => generateInvoice(row)} className="btn btn-success btn-sm">
                          Generate Invoice
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={numberOfPages}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"previous"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SucWithdraw;
