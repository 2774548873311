import React from "react";
import { Card } from "react-bootstrap";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const data = [
  {
    id: "342",
    Username: "User1",
    amount: "100",
    screenshot:
      "https://media.istockphoto.com/photos/amber-fort-and-maota-lake-jaipur-rajasthan-india-picture-id1135820309?s=612x612",
  },
  {
    id: "343",
    Username: "User1",
    amount: "100",
    screenshot:
      "https://media.istockphoto.com/photos/amber-fort-and-maota-lake-jaipur-rajasthan-india-picture-id1135820309?s=612x612",
  },
  {
    id: "344",
    Username: "User4",
    amount: "100",
    screenshot:
      "https://media.istockphoto.com/photos/amber-fort-and-maota-lake-jaipur-rajasthan-india-picture-id1135820309?s=612x612",
  },
];

const Withdrawlhistory = () => {
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Withdrawal History</h4>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Amount</th>
                    <th>Screenshot</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.id}</td>
                      <td>{item.Username}</td>
                      <td>{item.amount}</td>
                      <td>
                        <img
                          src={item.screenshot}
                          style={{
                            borderRadius: "5px",
                            width: "4rem",
                            height: "4rem",
                            transition: "width 0.3s, height 0.3s", // Smooth transition effect
                          }}
                          id={`ss${index}`}
                          onClick={() => {
                            const ss = document.getElementById(`ss${index}`);
                            const width = ss.style.width;
                            if (width === "4rem") {
                              ss.style.width = "100%";
                              ss.style.height = "100%";
                            } else {
                              ss.style.width = "4rem";
                              ss.style.height = "4rem";
                            }
                          }}
                          alt={`Screenshot ${index + 1}`}
                        />
                      </td>
                      <td>
                        <button className="btn btn-primary mr-2">Accept</button>
                        <button className="btn btn-danger">Reject</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawlhistory;
